@mixin toolbar-theme($theme) {

    $foreground: map-get($theme, foreground);

    toolbar {

        .toolbar-separator {
            background: map-get($foreground, divider);
        }
    }
}
/*@media screen and (min-width: 1181px) {
  .hiddenbar {
    display:block;
  }
  .showbar {
    display: none;
  }
}*/
@media screen and (min-width: 1181px) {
  .hiddenbar {
    display: block !important;
  }

  .showbar {
    display: none !important;
  }
}
@media screen and (max-width: 1180px ) {
  .hiddenbar {
    display: none !important;
  }

  .showbar {
    display: block !important;
    
  }
}
@media screen and (max-width: 466px) {
  .showbar {
    display: block !important;
    font-size:11px;
  }
}
