/* You can add global styles to this file, and also import other style files */
// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@font-face {
  font-family: 'THSarabunNew';
  src: url('assets/fonts/thsarabunnew_bold-webfont.eot');
  src: url('assets/fonts/thsarabunnew-webfont.eot?#iefix') format('embedded-opentype'), url('assets/fonts/thsarabunnew-webfont.woff') format('woff'), url('assets/fonts/thsarabunnew-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'THSarabunNew';
  src: url('assets/fonts/thsarabunnew_bolditalic-webfont.eot');
  src: url('assets/fonts/thsarabunnew_bolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('assets/fonts/thsarabunnew_bolditalic-webfont.woff') format('woff'), url('assets/fonts/thsarabunnew_bolditalic-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'THSarabunNew';
  src: url('assets/fonts/thsarabunnew_italic-webfont.eot');
  src: url('assets/fonts/thsarabunnew_italic-webfont.eot?#iefix') format('embedded-opentype'), url('assets/fonts/thsarabunnew_italic-webfont.woff') format('woff'), url('assets/fonts/thsarabunnew_italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'THSarabunNew';
  src: url('assets/fonts/thsarabunnew_bold-webfont.eot');
  src: url('assets/fonts/thsarabunnew_bold-webfont.eot?#iefix') format('embedded-opentype'), url('assets/fonts/thsarabunnew_bold-webfont.woff') format('woff'), url('assets/fonts/thsarabunnew_bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

html {
  overflow-y: scroll;
}

body {
  font-family: 'THSarabunNew', 'sans-serif';
  font-size: 14px;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1.12em;
  font-style: italic;
}

h5 {
  font-size: .83em;
  font-style: italic;
}

h6 {
  font-size: .75em;
  font-style: italic;
}

/* Default style for tables */
table {
  border-collapse: collapse;
  width: 100%;
}

th {
  background: #ccc;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
}

tr:nth-child(even) {
  background: #f6f6f6;
}

tr:hover {
  background: #fdfff3;
}
.myapp-no-padding-dialog .mat-dialog-container {
  padding: 0;
}
.example-spacer {
  flex: 1 1 auto;
}

.customWidthposition {
  flex: 0 0 75px;
}

.customWidthnameProject {
  flex: 0 0 400px;
}

.customWidthnameComplaint {
  flex: 0 0 250px;
}

.customWidthc_DepartmentName {
  flex: 0 0 250px;
}

.customWidthreport {
  flex: 0 0 30%;
}

.customWidthreportdescription {
  flex: 0 0 50%;
}

.customWidthnameProjectLong {
  flex: 0 0 800px;
}

.mat-disabled{
  pointer-events:none;
}

.fuse-card-set{
  min-width:unset;
}

.width-set{
  max-width:max-content !important;
}

@media only screen and (max-width: 1000px) {
  .table-response {
    width: 1000px;
  }
}

@media only screen and (max-width: 960px) {
  .barstatus-response {
    background:none !important;
  }
}
